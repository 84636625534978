import { iframeResizer } from "iframe-resizer";

function renderElement(root, type, props) {
  const element = document.createElement(type);
  Object.entries(props).forEach(([property, value]) => {
    if (property.startsWith("data-")) {
      element.setAttribute(property, value);
    } else {
      element[property] = value;
    }
  });
  root.appendChild(element);
  return element;
}

// TODO: Comment out the line below when developing locally
// const baseUrl = "http://localhost:3000";
const baseUrl = "https://25.tools";

(function () {
  const renderQueue = [];
  let isProcessing = false;

  async function fetchConfig(token) {
    const response = await fetch(
      `${baseUrl}/api/testimonial_widgets/config?token=${token}`
    );
    const data = await response.json();

    if (!data.data) {
      throw new Error("Could not fetch testimonials widget");
    }

    return data.data;
  }

  function renderWidget(widget, testimonialsContainer) {
    const iframe = renderElement(testimonialsContainer, "iframe", {
      title: "Testimonials Widget",
      "data-name": "testimonials-widget",
      name: "testimonials-widget",
      allow:
        "autoplay; display-capture *; microphone *; camera *; display-capture *; picture-in-picture *; fullscreen *; clipboard-write *;",
      src: `${baseUrl}/testimonials/widget/${widget.id}`,
      allowtransparency: "true",
      style:
        "width: 100%; height: 250px; min-height: 0px; min-width: 0px; margin: 0px; padding: 0px; background-image: none; background-position: 0% 0%; background-size: initial; background-attachment: scroll; background-origin: initial; background-clip: initial; background-color: rgba(0, 0, 0, 0); border-width: 0px; float: none; color-scheme: normal; inset: 0px; transition: none 0s ease 0s !important; display: block; visibility: visible;",
    });

    iframeResizer({}, iframe);
  }

  async function processRenderQueue() {
    if (isProcessing || renderQueue.length === 0) return;

    isProcessing = true;
    const container = renderQueue.shift();

    if (container.hasAttribute("data-processed")) {
      isProcessing = false;
      processRenderQueue();
      return;
    }

    const token = container.getAttribute("data-token");
    if (!token) {
      isProcessing = false;
      processRenderQueue();
      return;
    }

    try {
      const widget = await fetchConfig(token);
      renderWidget(widget, container);
      container.setAttribute("data-processed", "true");
    } catch (error) {
      console.error("Error rendering testimonial:", error);
    }

    isProcessing = false;
    processRenderQueue();
  }

  function enqueueRender(container) {
    if (
      !container.hasAttribute("data-processed") &&
      !renderQueue.includes(container)
    ) {
      renderQueue.push(container);
      processRenderQueue();
    }
  }

  function renderTestimonials() {
    const containers = document.querySelectorAll(
      '[data-name="testimonials-container"]:not([data-processed])'
    );

    containers.forEach(enqueueRender);
  }

  function observeDOMChanges() {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === "childList" || mutation.type === "subtree") {
          mutation.addedNodes.forEach((node) => {
            if (node.nodeType === Node.ELEMENT_NODE) {
              if (node.matches('[data-name="testimonials-container"]')) {
                enqueueRender(node);
              } else {
                node
                  .querySelectorAll('[data-name="testimonials-container"]')
                  .forEach(enqueueRender);
              }
            }
          });
        }
      });
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });
  }

  function init() {
    if (document.readyState === "loading") {
      document.addEventListener("DOMContentLoaded", renderTestimonials);
    } else {
      renderTestimonials();
    }
    observeDOMChanges();
  }

  init();
})();
